<template lang="html">
  <section class="panel-gestion" v-if="dataGestion" :class="dataGestion.name">
    <p class="title-line">{{ dataGestion.name }}</p>

    <!-- MENU GENERAL -->

    <div class="active content-panel-section">
      <p class="title">{{ dataGestion.subtitle }}</p>
      <!-- <p class="subtitle">{{ dataGestion.subtitle }}</p> -->
      <EditorJs :object="dataGestion.body" />
    </div>

  </section>
</template>

<script lang="js">
  import EditorJs from "@/components/editorJs.vue";
  export default {
    name: 'panel-gestion',
    props: ['dataGestion', 'slug'],
    components: {
      EditorJs
    },
    data() {
      return {
        menuSelected: 'gestions'
      }
    },
    methods: {
      openSubPanel: function (tab) {
        this.menuSelected = tab;
      }
    },
    watch: {
      dataGestion() {
        this.menuSelected = 'gestions';
      },
    },
  }

</script>